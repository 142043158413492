/**
 * 该页面用于全局覆盖metrial 样式
 * namespace .pl-cover
 */
.pl-cover {
  /**
    * TextField组件样式覆盖
    */
  .MuiOutlinedInput-notchedOutline {
    border-width: 1px !important;
    border-radius: 3px;
    border-color: #ddd;
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: $blue;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
  }
  // not valid
  .formItemInvalid {
    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: $invalid;
    }
    > div {
      border-color: $invalid;
    }
  }
  .MuiInputBase-root {
    font-size: 14px;
    font-family: 'Rubik Medium';
    font-weight: 500;
    color: $black;
  }
  .MuiOutlinedInput-input {
    padding: 16.5px 14px;
    // ++
    border-color: $bd-default;
    // ++
    &.Mui-disabled {
      background: #f0f0f0;
      // border-color: #fff;
    }
  }
  // ++ not active
  .formItemDisabled {
    .MuiFormControl-root {
      background: #f0f0f0;
    }
  }

  /**
    * Radio
    */
  .MuiTypography-body1 {
    font-size: 14px;
    font-family: 'Rubik Medium';
  }
  .MuiRadio-root .MuiSvgIcon-root {
    height: 16px;
    width: 16px;
  }

  /**
    * checkbox
    */
  .MuiCheckbox-root .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
  }

  /**
    * FormControl
    */
  .MuiFormControl-marginNormal {
    margin-top: 0;
    margin-bottom: 0;
  }
  .MuiSelect-icon {
    color: $black;
  }

  /**
    * Select
    */
  .MuiInputBase-inputSelect {
    font-family: 'Rubik Medium';
    color: #000;
  }

  /**
    * helperText
    */
  .MuiFormHelperText-contained {
    margin: 8px 0 0;
  }

  /**
    * Button
    */
  .MuiButton-root {
    height: 45px;
    padding: 12px 20px;
    font-size: 16px;
    line-height: 17px;
    border-radius: 3px;
    text-transform: none;
  }

  /**
    * Table（strategies&rules）
    */
  .MuiTableCell-body {
    color: $black;
    &.MuiTableCell-root {
      height: 80px;
      border-color: rgba(245, 245, 245, 1);
    }
  }
  .MuiTableRow-head .MuiTableCell-head {
    font-family: 'Rubik Medium';
    height: 60px;
    border-color: rgba(245, 245, 245, 1);
  }
  .MuiTableCell-root {
    padding: 14px 40px 14px 23px;
  }
}
