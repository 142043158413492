@import '../../../variable.scss';
// 弹窗
.pl-message {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  position: fixed;
  top: 16px;
  left: 0;
  z-index: 10000;
  width: 100%;
  pointer-events: none;

  &-notice {
    padding: 8px;
    text-align: center;
    &:first-child {
      margin-top: -8px;
    }
  }

  &-notice-content {
    display: inline-block;
    color: #fff;
    border-radius: 4px;
    // -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    // box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    pointer-events: all;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
      0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    animation-duration: 0.3s;
    animation-name: MessageMoveIn;
  }

  &-custom-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px 16px;
    border-radius: 4px;
  }

  &-success {
    background-color: $success;
  }

  &-error {
    background-color: $danger;
  }

  &-warning {
    background-color: $warning;
  }

  &-info,
  &-loading {
    background-color: $primary;
  }

  .icon-message {
    padding-right: 16px;
    font-size: 22px;
  }

  &-notice.move-up-leave.move-up-leave-active {
    overflow: hidden;
    animation-name: MessageMoveOut;
    animation-duration: 0.3s;
  }
}

@keyframes MessageMoveOut {
  0% {
    opacity: 1;
  }
  100% {
    transform: translateY(-150px);
    opacity: 0;
  }
}
@keyframes MessageMoveIn {
  0% {
    transform: translateY(-150px);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
