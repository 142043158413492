// 基础变量

// 颜色
$white: #fff;
$black: #000;
$black-normal: #191919;
$black-light: #4c4c4c;
$disabled: #999;

$blue: #4285f4; // 蓝色
// $active: #096; // 激活色
$primary: #1890ff; // 主色
// $link: $active; // 链接色
$green: #1ca362; // 绿色
$success: $green; // 成功色
// $danger: #f44336; // 危险色
$danger: #de5247; // 危险色
$invalid: #f44336;  // 验证不通过
$warning: #ff9933; // 警告色
// $pink: #f8a8a8; // 粉色
$disabled: #bec3c9; // 禁用色

// 背景
$bg-default: #f5f6f9; // 大面积背景色
$bd-nav: #f5f5f5; // 条幅背景

// 边框
$bd-default: #ddd;
$bd-light: #eee;
